import React from 'react'
import { graphql } from 'gatsby'
//
import Layout from '~components/layout'
import SEO from '~components/seo'
import { SearchLayout } from '~storybook/layout'
import Search, { SearchResults } from '~components/search'
import { getUrlParameter } from '~utils/common'
import { isSSR } from '~utils/ssr'

const IndexPage = ({ data, location, pageContext }) => {
  const { language, menus } = pageContext

  const { group } = data.drupal
  const {
    fieldGdprTitle,
    fieldGdprDescription,
    fieldAnnouncement,
    fieldGdprCookies = [],
    fieldMetaSearchTitle,
    fieldMetaSearchDescription,
    fieldMetaSiteImage
  } = group
  const langCode = language.toLowerCase()
  const langMenus = menus[langCode] || {}
  const initialQuery = getUrlParameter(location?.search, 'q')

  const [results, setResults] = React.useState([])

  const handleResults = (res) => {
    const searchQuery = res?.searchQuery || ''
    setResults(res?.results || [])
    if (!isSSR) {
      window.history.pushState('', document.title, `${location?.pathname}?q=${searchQuery}`)
    }
  }

  const newMetatags = []

  newMetatags.push({
    __typename: 'MetaValue',
    key: 'title',
    value: fieldMetaSearchTitle
  })

  newMetatags.push({
    __typename: 'MetaValue',
    key: 'description',
    value: fieldMetaSearchDescription
  })

  newMetatags.push({
    __typename: 'MetaValue',
    key: 'twitter:description',
    value: fieldMetaSearchDescription
  })

  return (
    <Layout
      language={langCode}
      menus={langMenus}
      announcement={fieldAnnouncement}
      cookiesInfo={{ fieldGdprTitle, fieldGdprDescription, fieldGdprCookies }}
    >
      <SEO
        key={`search-${langCode}`}
        title="Search"
        lang={langCode}
        metatags={newMetatags}
        globalImage={fieldMetaSiteImage?.entity?.thumbnail?.url}
      />
      <SearchLayout
        key={`search-${langCode}-${initialQuery}`}
        search={<Search initialQuery={initialQuery} language={langCode} handleResults={handleResults} />}
        results={<SearchResults results={results} />}
      />
    </Layout>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query($gid: String!, $language: Drupal_LanguageId!) {
    drupal {
      ...FragmentLangs
      group: groupById(id: $gid, language: $language) {
        ...FragmentSite
      }
    }
  }
`
